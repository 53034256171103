import React, { useEffect } from "react";

export default (props) => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      let location = window.location;
      if (props.location.href.includes("dev")) {
        window.open(
          `https://dev-admin.in.springverify.com${
            location.pathname === "/admin" || location.pathname === "/admin/"
              ? "/dashboard"
              : location.pathname
          }${location.search}`,
          "_self"
        );
      } else if (props.location.href.includes("acceptance")) {
        window.open(
          `https://acceptance-admin.in.springverify.com${
            location.pathname === "/admin" || location.pathname === "/admin/"
              ? "/dashboard"
              : location.pathname
          }${location.search}`,
          "_self"
        );
      } else {
        window.open(
          `https://admin.in.springverify.com${
            location.pathname === "/admin" || location.pathname === "/admin/"
              ? "/dashboard"
              : location.pathname
          }${location.search}`,
          "_self"
        );
      }
    }
  });
  return <></>;
};
